import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ReactComponent as Close } from 'style/icons/close.svg';
import './ResponsiveModal.scss';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import classnames from 'classnames';
import Label from 'components/form/Label/Label';
import { ReactComponent as LeftArrow } from 'style/icons/chevron-left.svg';
import VisibilitySensor from 'react-visibility-sensor';
import { useScrollToTopOfModal } from 'hook/helper.hook';
import { isDeepEqual, isObj } from 'helpers/objectHelpers';
import { adaModalAccessibility } from './ada/ModelADA.helper';

export const zIndex = {
  modalBase: 1000, // Base modal
  modalPurchaseConfirming: 1100,
  modalFreeUserOnboarding: 1200,
};

export const ModalHeader = ({
  header,
  headerTitle,
  onCancel,
  datatestid,
  titleInViewState,
  showBackArrow,
  goBackHandler,
  showTitleAndArrow,
  headerButton,
  isHeaderSmaller,
  closable = true,
}) => {
  const headerTitleContainerValidation = classnames(
    'align-items-center',
    headerButton ? 'contents' : '',
  );
  const headerLabel = classnames('headerTitle margB0', {
    'text-14': isHeaderSmaller,
  });

  return (
    <FlexContainer className="responsiveModalHeader align-items-center justify-between">
      <FlexContainer className={headerTitleContainerValidation}>
        {showBackArrow && (
          <LeftArrow className="link" onClick={goBackHandler} />
        )}
        {headerButton}
      </FlexContainer>

      {!titleInViewState && headerTitle && (
        <>
          <div className="headerTitleSection">
            <Label
              className={headerLabel}
              datatestid={`${datatestid}-headerTitle`}
              type="H5"
              typeHeader={'h5'}
              dangerouslySetInnerHTML={{
                __html: headerTitle,
              }}
            />
          </div>
        </>
      )}

      <FlexContainer className="align-items-center">
        {header}
        {closable && (
          <div className="closeIcon">
            <Close onClick={onCancel} />
          </div>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

const CallbackWrapper = props => {
  //eslint-disable-next-line
  useEffect(props.callback, []);
  return '';
};

function ResponsiveModal({
  datatestid,
  visible,
  onCancel,
  footer,
  header,
  children,
  bodyStyle,
  closable,
  maskClosable,
  typeClass,
  width,
  goBack,
  goBackHandler,
  showBackArrow,
  headerTitle,
  wrapClassName,
  titleClass,
  headerAvailable = true,
  headerButton,
  isHeaderTitleAlwaysShown,
  isHeaderSmaller,
  hideTitleOnSlide = false,
  customBackground,
  ariaLabel = 'Modal',
  ...props
}) {
  useScrollToTopOfModal('.ant-modal-body');
  const initModalState = {
    removeTopShadow: true,
    removeBotShadow: true,
    titleInViewState: true,
    modalRendered: false,
    footerHeight: null,
    headerHeight: null,
    container: null,
  };
  const [modalState, setModalState] = useState(initModalState);
  const showTitleAndArrow = showBackArrow && headerTitle && headerAvailable;
  let responsiveModalWrapClass = classnames(
    wrapClassName,
    'responsiveModal',
    typeClass,
    {
      shadow: !modalState.removeTopShadow,
      withFooter: footer,
      noTitle: !headerTitle,
      titleAndArrow: showTitleAndArrow,
      removeBotShadow: modalState.removeBotShadow,
    },
  );

  const updateModalState = updateObj => {
    if (isObj(updateObj)) {
      const newState = Object.assign({}, modalState, updateObj);
      if (!isDeepEqual(newState, modalState)) {
        setModalState(newState);
      }
    }
  };

  const onVisibilityChangeTop = isVisible => {
    if (isVisible && modalState.container && modalState.headerHeight > 0) {
      const isTitleInViewState =
        !hideTitleOnSlide && (headerButton || isHeaderTitleAlwaysShown);
      updateModalState({
        removeTopShadow: true,
        titleInViewState: !isTitleInViewState,
      });
      adaModalAccessibility(ariaLabel);
    } else if (
      !isVisible &&
      modalState.container &&
      modalState.headerHeight > 0
    ) {
      updateModalState({ removeTopShadow: false, titleInViewState: false });
    }
  };

  const onVisibilityChangeBot = isVisible => {
    if (isVisible && modalState.container && modalState.footerHeight > 0) {
      updateModalState({ removeBotShadow: true });
    } else {
      updateModalState({ removeBotShadow: false });
    }
  };

  const shadowHandlerCallback = () => {
    const newModalState = {};
    if (!modalState.modalRendered) {
      newModalState.modalRendered = true;
    }
    if (!modalState.footerHeight || modalState.footerHeight === 0) {
      const footerElement =
        document.getElementsByClassName('ant-modal-footer')[0];
      if (footerElement) {
        newModalState.footerHeight = footerElement.clientHeight;
      }
    }
    if (!modalState.headerHeight || modalState.headerHeight === 0) {
      const headerElement =
        document.getElementsByClassName('ant-modal-header')[0];
      if (headerElement) {
        newModalState.headerHeight = headerElement.clientHeight - 10;
      }
    }
    if (!modalState.container) {
      const modalContentElement =
        document.getElementsByClassName('ant-modal-content')[0];
      newModalState.container = modalContentElement;
    }

    if (Object.keys(newModalState).length > 0) {
      updateModalState(newModalState);
    }

    return function cleanupState() {
      setModalState(initModalState);
    };
  };

  let customContainerClass = customBackground
    ? classnames('responsiveModalContent', customBackground)
    : 'responsiveModalContent';

  return (
    <Modal
      onCancel={onCancel}
      destroyOnClose={true}
      transitionName=""
      title={
        <ModalHeader
          header={header}
          headerTitle={headerTitle}
          onCancel={onCancel}
          datatestid={datatestid}
          goBack={goBack}
          titleInViewState={modalState.titleInViewState}
          goBackHandler={goBackHandler}
          showBackArrow={showBackArrow}
          headerButton={headerButton}
          showTitleAndArrow={showTitleAndArrow}
          isHeaderSmaller={isHeaderSmaller}
          closable={closable}
        />
      }
      footer={footer}
      visible={visible}
      closable={closable}
      maskClosable={maskClosable}
      bodyStyle={bodyStyle}
      width={width ? width : '720px'}
      data-testid={datatestid ? `${datatestid}-modal` : null}
      wrapClassName={responsiveModalWrapClass}
      {...props}
    >
      <div className={customContainerClass}>
        <CallbackWrapper
          callback={shadowHandlerCallback}
          headerTitle={headerTitle}
          titleClass={titleClass}
        />
        <VisibilitySensor
          containment={modalState.container}
          onChange={onVisibilityChangeTop}
          offset={{ top: modalState.headerHeight }}
          active={
            modalState.modalRendered &&
            modalState.container &&
            modalState.headerHeight > 0
          }
          intervalDelay={100}
          delayedCall
        >
          <div className="invisible-pixel top-pixel"></div>
        </VisibilitySensor>
        {children}
        <VisibilitySensor
          containment={modalState.container}
          onChange={onVisibilityChangeBot}
          offset={{ bottom: modalState.footerHeight }}
          active={
            modalState.modalRendered &&
            modalState.container &&
            modalState.footerHeight > 0
          }
          partialVisibility
          intervalDelay={100}
          delayedCall
        >
          <div className="invisible-pixel"></div>
        </VisibilitySensor>
      </div>
    </Modal>
  );
}

export { ResponsiveModal };
