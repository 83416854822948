import { wrapService } from 'actions/service_wrapper.actions';
import {
  DUPE_SSN_FLOW,
  ocsFlowTracking,
  oneClickSignupConstants as signupConstants,
  optEventsSignupSubmit,
} from './OneClickSignup.constants';
import {
  CLICK,
  REGISTER,
  SIGNUP_ACCOUNT_CREATED,
  SIGNUP_FINISH,
} from 'externals/_tracking/types/eventTypes.constants';
import { manuallyActivatePage } from 'externals/_tracking/optimizely/optimizely.util';
import moment from 'moment';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from 'types/app.constants';
import { getUserInfo } from 'actions/userInfo.actions';
import {
  authenticatePhone,
  getIdentity,
  getIdentityLTE,
  prefillFinish,
  prefillStart,
  verifyFingerprint,
  verifyOtp,
  validateAuthStatus,
  continueAuth,
  completeFreeAccountRegistration,
} from 'externals/_services/oneClickSignup.service';
import { reportOptimizely } from 'externals/_tracking/index';
import {
  publicLogin,
  pushUserProfileOptAttributes,
} from 'actions/login.actions';
import { retrieveCreditProfile } from 'actions/creditInfo.actions';
import {
  getCommonTrackingD2PAttributes,
  getPixelUrl,
  getSignupData,
  isCreditProfileError,
  isSsnMismatchError,
  isTestFlow,
  persistSignupData,
  sendSMSVerificationEvent,
} from 'helpers/oneClickSignup.helpers';
import { partnerDecrypt } from 'externals/_services/prefilledSignup.service';
import {
  getAndClearEncryptedEmail,
  getMarketingCookieAttr,
} from 'helpers/marketing.helper';
import { getSlapiApiKey, isPrequalOfferAccept } from 'helpers/env';
import {
  isPendingPaymentOnSignUp,
  isPremium,
  isUserRegisteredBefore,
} from 'helpers/userHelper';
import {
  getAffiliatePixels,
  userService,
  guaranteeTrk,
  verifyPhoneDupSsnKBA,
} from 'externals/_services/user.service';
import {
  buildUserMeta,
  getAttributesForRegister,
} from 'helpers/tracking.helpers';
import { triggerClientPixel } from 'helpers/pixelHelper';
import { brazeEventTrack } from 'externals/_tracking/braze/Braze.api';
import {
  deleteCookieValueForCSDomain,
  getCookieValue,
  setCookieWithExpiration,
} from 'helpers/cookie.helper';
import {
  getCSPublicTrack,
  setFirstNameUser,
  setUserLogoutTime,
} from 'helpers/localstorage';
import { createLogger } from 'helpers/logger';
import {
  initUserTracking,
  OPT_SIGNUP_ACCOUNT_CREATED,
  OPT_SIGNUP_COMPLETE,
} from '../signup.tracking';
import { getFreshUserInfo } from '../actions/signup.actions';
import { formatToHttps } from 'externals/_services/service_helpers';
import { setTempPassword } from './OneClickTUSignup.actions';
import {
  OCS_ACCT_CREATION_NO_PREFILL,
  OCS_ACCT_CREATION_PREFILLED,
  OCS_CONFIRM_INFO_FORM,
  OCS_DUPE_SSN_LOADING,
  OCS_EDIT_ALL_FORM,
  OCS_EMAIL_STEP_FALLBACK_FORM,
  OCS_ADDRESS_STEP_FALLBACK_FORM,
  OCS_OTP_SENT,
  OCS_SMS_SENT,
  OCS_CHECK_YOUR_TEXT_SENT,
} from 'types/mrph.pages.constants';
import { standardEventHandler } from 'actions/eventHandler.actions';
import { identifyUser } from 'externals/_tracking/impact/impact.track';
import { trackSignupForTvScientific } from 'externals/_tracking/tvScientific/tv_scientific.track';
import {
  OVERVIEW_URL,
  OVERVIEW_WELCOME_URL,
} from 'types/mrph.routes.constants';
import { goToUrl } from 'helpers/domHelper';
import { getGetParam, completeUrl } from 'helpers/domHelper';
import { LOAD_D2P_DATA } from 'types/d2p.constants';
import { goToLogin } from 'customHelpersPath/redirectLogin';

const ocs1_prefillStart = wrapService(prefillStart, {
  name: 'ocs1_prefillStart',
});
const ocs1_verifyFingerprint = wrapService(verifyFingerprint, {
  name: 'ocs1_verifyFingerprint',
});

const ocs1_continueAuth = wrapService(continueAuth, {
  name: 'ocs1_continueAuth',
});

const ocs1_authenticatePhone = wrapService(authenticatePhone, {
  name: 'ocs1_authenticatePhone',
});
const ocs1_getIdentity = wrapService(getIdentity, {
  name: 'ocs1_getIdentity',
});
const ocs1_getIdentityLTE = wrapService(getIdentityLTE, {
  name: 'ocs1_getIdentityLTE',
});
const ocs1_partnerDecrypt = wrapService(partnerDecrypt, {
  name: 'ocs1_partnerDecrypt',
});
const ocs1_prefillFinish = wrapService(prefillFinish, {
  name: 'ocs1_prefillFinish',
});
const ocs1_loginUser = wrapService(userService.login, {
  name: 'ocs1_loginUser',
});

const ocs1_verifyOtp = wrapService(verifyOtp, {
  name: 'ocs1_verifyOtp',
});

const ocs1_validateAuthStatus = wrapService(validateAuthStatus, {
  name: 'ocs1_validateAuthStatus',
});

const ocs1_verifyOTPDeviceVerification = wrapService(verifyPhoneDupSsnKBA, {
  name: 'ocs1_verifyOTPDeviceVerification',
});

const ocs1_completeFreeAccountRegistration = wrapService(
  completeFreeAccountRegistration,
  {
    name: 'ocs1_completeFreeAccountRegistration',
  },
);

const logger = createLogger({
  name: 'OneClickSignup.actions',
  categories: ['signup'],
  includeVisitor: true,
});

const fingerPrintVerificationPayload = (
  firgerPrintVerification,
  fingerPrintVerificationLoading,
  fingerPrintVerificationError,
) => ({
  type: signupConstants.SET_FINGER_PRINT_VERIFICATION,
  firgerPrintVerification,
  fingerPrintVerificationLoading,
  fingerPrintVerificationError,
});

export const startFingerPrintVerification = () =>
  fingerPrintVerificationPayload(null, true, null);
export const endFingerPrintVerification = () =>
  fingerPrintVerificationPayload(null, false, null);
const succeedFingerPrintVerification = payload =>
  fingerPrintVerificationPayload(payload, false, null);
const failFingerPrintVerification = error =>
  fingerPrintVerificationPayload(null, false, error);

export const prefillStartAction = isWifi => async (dispatch, getState) => {
  const res = await dispatch(
    ocs1_prefillStart({ finalTargetUrl: getPixelUrl() }, isWifi),
  );
  const redirectTargetUrl = res.redirectTargetUrl;
  const message = redirectTargetUrl
    ? 'Prefill Start eligible'
    : 'Prefill Start non-eligible';
  logger.info(message);
  return redirectTargetUrl;
};

export const checkRedirectUrl = redirectUrl => async (dispatch, getState) => {
  const finalRedirectUrl = isTestFlow()
    ? `${getPixelUrl()}?vfp=mock-123456`
    : redirectUrl;
  dispatch({
    type: signupConstants.SET_REDIRECT_URL,
    redirectUrl: formatToHttps(finalRedirectUrl),
  });
};

export const checkFingerprint =
  (vfp, purpose, sessionIdentifier) => async (dispatch, getState) => {
    let params = { vfp, purpose };
    if (isTestFlow()) {
      let extraParams = {};
      extraParams = Object.assign({}, params, {
        submittedSessionIdentifier: sessionIdentifier
          ? sessionIdentifier
          : getCSPublicTrack(),
      });
      params = Object.assign({}, params, extraParams);
    }

    const res = await dispatch(ocs1_verifyFingerprint(params));
    return res;
  };

export const checkContinueAuth = queryOptions => async (dispatch, getState) => {
  try {
    const loggedIn = getState().app?.loggedIn;
    if (!loggedIn) {
      await dispatch(publicLogin());
    }
    const res = await dispatch(ocs1_continueAuth(queryOptions));
    goToUrl(res?.authUrl);
    return res;
  } catch (err) {
    logger.reportAPIError(err, 'checkContinueAuth.OneClickSignup.actions');
    dispatch(failFingerPrintVerification(err));
  }
};

export const checkEncryptedEmail = () => async (dispatch, getState) => {
  const encryptedData = getAndClearEncryptedEmail();
  if (!encryptedData) {
    return;
  }

  try {
    const res = await dispatch(
      ocs1_partnerDecrypt({ encrypted: encryptedData }, 'TPH'),
    );
    const decryptedEmail = res?.decrypted;
    if (
      typeof decryptedEmail === 'string' ||
      decryptedEmail instanceof String
    ) {
      return decryptedEmail;
    }
  } catch (err) {
    logger.reportAPIError(err, 'checkEncryptedEmail.OneClickSignup.actions');
  }
};

export const initPrefill =
  (isWifi, eventHandler) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());

      await dispatch(publicLogin());
      const decryptedEmail = await dispatch(checkEncryptedEmail());
      const redirectTargetUrl = await dispatch(prefillStartAction(isWifi));

      if (redirectTargetUrl) {
        await dispatch(checkRedirectUrl(redirectTargetUrl));
      } else {
        dispatch(setSignup1Step(OCS_ACCT_CREATION_NO_PREFILL));
        dispatch(stopLoading());
      }
      return { decryptedEmail };
    } catch (err) {
      logger.reportAPIError(err, 'initPrefill.OneClickSignup.actions');
      dispatch(setSignup1Step(OCS_ACCT_CREATION_NO_PREFILL));
      dispatch(stopLoading());
      throw err;
    }
  };

export const initPrefillToReSendOTP = () => async dispatch => {
  try {
    dispatch(startLoading());

    await dispatch(publicLogin(undefined, true));
    const decryptedEmail = await dispatch(checkEncryptedEmail());

    dispatch(stopLoading());
    return { decryptedEmail };
  } catch (err) {
    logger.reportAPIError(err, 'initPrefillToReSendOTP.OneClickSignup.actions');
    dispatch(stopLoading());
    throw err;
  }
};

export const submitVfpPrefillEligible =
  (vfp, eventHandler) => async (dispatch, getState) => {
    try {
      const phoneNumberRes = dispatch(
        checkFingerprint(vfp, 'GET_PHONE_NUMBER'),
      );

      dispatch(setSignup1Step(OCS_ACCT_CREATION_PREFILLED));
      return phoneNumberRes;
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitVfpPrefillEligible.OneClickSignup.actions',
      );
      dispatch(setSignup1Step(OCS_ACCT_CREATION_NO_PREFILL));
      throw err;
    } finally {
      dispatch(stopLoading());
    }
  };

export const handleVfpIFrameErrors =
  (signupStep, history) => (dispatch, getState) => {
    logger.info('Error getting vfp from iFrame');
    if (signupStep !== OCS_OTP_SENT) {
      dispatch(setSignup1Step(OCS_ACCT_CREATION_NO_PREFILL));
    } else {
      const signup1Data = getSignup1FormData(getState);
      dispatch(
        saveAndRedirectToSignup2(
          signup1Data,
          OCS_EMAIL_STEP_FALLBACK_FORM,
          history,
        ),
      );
    }
    dispatch(stopLoading());
  };

export const saveAndRedirectToSignup2 =
  (signup1Data, signup2Step, history) => dispatch => {
    dispatch(standardEventHandler(ocsFlowTracking[signup2Step]));
    let signupData = getSignupData();
    if (signupData) {
      signupData.signup1 = signup1Data;
      signupData.signup2.step = signup2Step;
    } else {
      signupData = { signup1: signup1Data, signup2: { step: signup2Step } };
    }
    persistSignupData(signupData);
    dispatch(setGoOneClickSignup2());
  };

export const submitSignup1NoPrefillForm =
  (
    isProveInstantLink,
    formData,
    isMobile,
    history,
    phoneAttrs,
    trackingAttrs,
    signupStep,
    variation2 = false,
    changeSSNbyDOB = false,
    redirectToSignup2Attr = true,
  ) =>
  async (dispatch, getState) => {
    dispatch(startLoading());
    const state = getState();
    const { isSsnValidation } = state.oneClickSignup;
    const { direct2Premium, planCode } = state.d2p || {};
    const signup1Data = Object.assign({}, formData, phoneAttrs);
    try {
      dispatch(setPrefillPhoneEmailResponse(true));
      const input = {
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        ssn: formData.last4Ssn,
        apiKey: getSlapiApiKey(),
        authMode: isProveInstantLink ? 'AUTH_URL' : 'OTP',
        finalTargetUrl: completeUrl('/oneClickSignup/cont'),
      };
      if (direct2Premium) {
        input.direct2Premium = direct2Premium;
        input.subscriptionPlan = planCode;
      }
      const registrationRes = await dispatch(ocs1_authenticatePhone(input));

      signup1Data.tempPassword = registrationRes.password;
      setTempPassword(registrationRes.password);

      await dispatch(
        userLogin(registrationRes, formData, trackingAttrs, isSsnValidation),
      );
      await dispatch(getFreshUserInfo());

      dispatch({
        type: signupConstants.SIGNUP_1_NO_PREFILL_SUBMIT_SUCCESS,
        ...signup1Data,
        changeSSNbyDOB,
      });
      dispatch(reportSignup1Submit(signupStep, trackingAttrs));
      if (isProveInstantLink) {
        dispatch(setSignup1Step(OCS_CHECK_YOUR_TEXT_SENT));
        dispatch(
          sendSMSVerificationEvent('Sent', trackingAttrs, 'SMS Instant link'),
        );
      } else {
        if (isMobile) {
          dispatch(setSignup1Step(OCS_OTP_SENT));
          dispatch(sendSMSVerificationEvent('Sent', trackingAttrs, 'OTP'));
        } else {
          dispatch(setSignup1Step(OCS_SMS_SENT));
          dispatch(sendSMSVerificationEvent('Sent', trackingAttrs, 'SMS'));
        }
      }
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitSignup1NoPrefillForm.OneClickSignup.actions',
      );
      if (err.errorCode !== 'SU_1002') {
        dispatch(setEligibilityResponse(false));
        if (redirectToSignup2Attr) {
          dispatch(
            saveAndRedirectToSignup2(
              signup1Data,
              variation2
                ? OCS_ADDRESS_STEP_FALLBACK_FORM
                : OCS_EMAIL_STEP_FALLBACK_FORM,
              history,
            ),
          );
        }
      }
      throw err;
    } finally {
      dispatch(stopLoading());
    }
  };

const completeRegistrationFlowStep1 = async (
  signup1Data,
  identityRes,
  formData,
  history,
  trackingAttrs,
  changeSSNbyDOB,
  signupStep,
  dispatch,
) => {
  signup1Data.tempPassword = identityRes.password;
  setTempPassword(identityRes.password);

  dispatch(setEligibilityResponse(identityRes.eligibleForPrefill));
  dispatch(setPrefillPhoneEmailResponse(true));

  dispatch(setProveElegibleAudience(identityRes.eligibleForPrefill));

  await dispatch(userLogin(identityRes, formData, trackingAttrs, false));
  await dispatch(getFreshUserInfo());

  signup1Data.isPrefillEligible = identityRes.eligibleForPrefill;
  if (!identityRes.eligibleForPrefill) {
    throw identityRes;
  }
  signup1Data.changeSSNbyDOB = changeSSNbyDOB;
  dispatch(reportSignup1Submit(signupStep, trackingAttrs));
  const signupData = { signup2: identityRes };
  persistSignupData(signupData);

  dispatch(
    saveAndRedirectToSignup2(signup1Data, OCS_CONFIRM_INFO_FORM, history),
  );
};

export const submitSignup1PrefillForm =
  (
    formData,
    history,
    phoneAttrs,
    trackingAttrs,
    signupStep,
    variation2 = false,
    changeSSNbyDOB = false,
  ) =>
  async (dispatch, getState) => {
    dispatch(startLoading());
    const { isSsnValidation } = getState().oneClickSignup;
    const signup1Data = Object.assign({}, formData, phoneAttrs);
    try {
      const identityRes = await dispatch(
        ocs1_getIdentityLTE({ ...formData, apiKey: getSlapiApiKey() }),
      );

      if (identityRes?.needsOtp) {
        await dispatch(userLogin(identityRes, formData, trackingAttrs, false));
        dispatch(
          setVerifyOTPConfig({
            identityRes,
            formData,
            changeSSNbyDOB,
            signupStep,
            signupData: signup1Data,
          }),
        );
        dispatch(setSignup1Step(OCS_OTP_SENT));
      } else {
        await completeRegistrationFlowStep1(
          signup1Data,
          identityRes,
          formData,
          history,
          trackingAttrs,
          changeSSNbyDOB,
          signupStep,
          dispatch,
        );
      }
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitSignup1PrefillForm.OneClickSignup.actions',
      );
      if (isSsnMismatchError(err) && !isSsnValidation) {
        dispatch(initSsnValidation());
        dispatch(setSignup1Step(OCS_ACCT_CREATION_PREFILLED));
      } else if (err.errorCode !== 'SU_1002') {
        dispatch(
          saveAndRedirectToSignup2(
            signup1Data,
            variation2
              ? OCS_ADDRESS_STEP_FALLBACK_FORM
              : OCS_EMAIL_STEP_FALLBACK_FORM,
            history,
          ),
        );
      }
      throw err;
    } finally {
      dispatch(stopLoading());
    }
  };

const getSignup1FormData = getState => {
  const signupState = getState().oneClickSignup;
  return {
    email: signupState.email,
    phoneNumber: signupState.phoneNumber,
    last4Ssn: signupState.last4Ssn,
    dob: signupState.dob,
    tempPassword: signupState.tempPassword,
  };
};

export const clickTextNotReceived =
  (history, variation2) => (dispatch, getState) => {
    dispatch(startLoading());
    const signup1Data = getSignup1FormData(getState);
    dispatch(
      saveAndRedirectToSignup2(
        signup1Data,
        variation2
          ? OCS_ADDRESS_STEP_FALLBACK_FORM
          : OCS_EMAIL_STEP_FALLBACK_FORM,
        history,
      ),
    );
    dispatch(stopLoading());
  };

export const clickSignupManually = history => (dispatch, getState) => {
  dispatch(clickTextNotReceived(history));
};

export const getIdentityValidation =
  (formData, history, trackingAttrs) => async (dispatch, getState) => {
    const signup1Data = Object.assign({}, formData, trackingAttrs);
    const identityRes = await dispatch(ocs1_getIdentity(formData));
    dispatch(setEligibilityResponse(identityRes.eligibleForPrefill));
    dispatch(setPrefillPhoneEmailResponse(true));
    signup1Data.isPrefillEligible = identityRes.eligibleForPrefill;
    if (!identityRes.eligibleForPrefill) {
      throw identityRes;
    }

    const signupData = { signup2: identityRes };
    persistSignupData(signupData);
    dispatch(
      saveAndRedirectToSignup2(signup1Data, OCS_CONFIRM_INFO_FORM, history),
    );
    return identityRes;
  };

export const submitSignup1VerifyOTP =
  (
    code,
    newCodeAttempts,
    history,
    trackingAttrs,
    variation2,
    redirectToSignup2Attr = true,
    verifyOTPConfig,
  ) =>
  async (dispatch, getState) => {
    dispatch(startLoading());
    const signup1Data = verifyOTPConfig?.signupData
      ? verifyOTPConfig?.signupData
      : getSignup1FormData(getState);

    try {
      if (verifyOTPConfig?.identityRes?.needsOtp) {
        const {
          identityRes,
          formData,
          changeSSNbyDOB,
          signupStep,
          signupData,
        } = verifyOTPConfig;
        const res = await dispatch(
          ocs1_verifyOTPDeviceVerification(code, 'PREFILL_DEVICE_VERIFICATION'),
        );
        if (res) {
          await completeRegistrationFlowStep1(
            signupData,
            identityRes,
            formData,
            history,
            trackingAttrs,
            changeSSNbyDOB,
            signupStep,
            dispatch,
          );
          dispatch(stopLoading());
        }
      } else {
        const res = await dispatch(
          ocs1_verifyOtp({ passCode: code, finalTargetUrl: getPixelUrl() }),
        );
        dispatch(reportSignup1OTPSubmit(trackingAttrs));
        dispatch(checkRedirectUrl(res.authUrl));
      }
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitSignup1VerifyOTP.OneClickSignup.actions',
      );
      dispatch(sendSMSVerificationEvent('failed', trackingAttrs, 'OTP'));
      if (newCodeAttempts >= 3) {
        dispatch(setEligibilityResponse(false));
        if (redirectToSignup2Attr) {
          dispatch(
            saveAndRedirectToSignup2(
              signup1Data,
              variation2
                ? OCS_ADDRESS_STEP_FALLBACK_FORM
                : OCS_EMAIL_STEP_FALLBACK_FORM,
              history,
            ),
          );
        }
      }
      dispatch(stopLoading());
      throw err;
    }
  };

export const redirectToSignup2 =
  (history, variation2) => async (dispatch, getState) => {
    dispatch(startLoading());
    const signup1Data = getSignup1FormData(getState);
    dispatch(
      saveAndRedirectToSignup2(
        signup1Data,
        variation2
          ? OCS_ADDRESS_STEP_FALLBACK_FORM
          : OCS_EMAIL_STEP_FALLBACK_FORM,
        history,
      ),
    );
    dispatch(stopLoading());
  };

export const submitFingerPrintVerification = vfp => async dispatch => {
  dispatch(startFingerPrintVerification());
  try {
    await dispatch(publicLogin());
    const vfpRes = await dispatch(
      checkFingerprint(
        vfp,
        'GET_INSTANT_LINK_RESULT',
        getGetParam('sessionIdentifier'),
      ),
    );
    dispatch(succeedFingerPrintVerification(vfpRes));
  } catch (err) {
    logger.reportAPIError(err);
    dispatch(failFingerPrintVerification(err));
  }
};

export const submitSignup1VerifyVfpForOTP =
  (vfp, history, trackingAttrs, eventHandler, variation2 = false) =>
  async (dispatch, getState) => {
    const signup1Data = getSignup1FormData(getState);
    try {
      const vfpRes = await dispatch(checkFingerprint(vfp, 'GET_AUTH_PATH'));

      if (
        vfpRes &&
        (vfpRes.authPath === 'GREEN' || vfpRes.authPath === 'YELLOW')
      ) {
        dispatch(sendSMSVerificationEvent('Verified', trackingAttrs, 'OTP'));
        await dispatch(
          getIdentityValidation(signup1Data, history, trackingAttrs),
        );
      } else {
        dispatch(sendSMSVerificationEvent('failed', trackingAttrs, 'OTP'));
        dispatch(
          saveAndRedirectToSignup2(
            signup1Data,
            variation2
              ? OCS_ADDRESS_STEP_FALLBACK_FORM
              : OCS_EMAIL_STEP_FALLBACK_FORM,
            history,
          ),
        );
        return vfpRes;
      }
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitSignup1VerifyVfpForOTP.OneClickSignup.actions',
      );
      const { isSsnValidation } = getState().oneClickSignup;
      if (isSsnMismatchError(err) && !isSsnValidation) {
        dispatch(initSsnValidation());
        dispatch({
          type: signupConstants.SET_REDIRECT_URL,
          redirectUrl: null,
        });
        dispatch(setSignup1Step(OCS_ACCT_CREATION_NO_PREFILL));
      } else {
        dispatch(sendSMSVerificationEvent('failed', trackingAttrs, 'OTP'));
        dispatch(
          saveAndRedirectToSignup2(
            signup1Data,
            variation2
              ? OCS_ADDRESS_STEP_FALLBACK_FORM
              : OCS_EMAIL_STEP_FALLBACK_FORM,
            history,
          ),
        );
      }
      throw err;
    } finally {
      dispatch(stopLoading());
    }
  };

export const authStatusValidation =
  (history, trackingAttrs, timeout, variation2) =>
  async (dispatch, getState) => {
    const signup1Data = getSignup1FormData(getState);
    try {
      //res.authPath should be deleted ones instant prove link is normalized
      const res = await dispatch(ocs1_validateAuthStatus(timeout));
      if (res.authStatus === 'STARTED' || res.authState === 'STARTED') {
        return res;
      } else if (
        res.authPath === 'GREEN' ||
        res.authPath === 'YELLOW' ||
        res.authStatus === 'COMPLETED_SUCCESS'
      ) {
        await dispatch(
          getIdentityValidation(signup1Data, history, trackingAttrs),
        );
      } else {
        dispatch(
          saveAndRedirectToSignup2(
            signup1Data,
            variation2
              ? OCS_ADDRESS_STEP_FALLBACK_FORM
              : OCS_EMAIL_STEP_FALLBACK_FORM,
            history,
          ),
        );
      }
      return res;
    } catch (err) {
      logger.reportAPIError(err, 'authStatusValidation.OneClickSignup.actions');
      dispatch(setEligibilityResponse(false));
      dispatch(
        saveAndRedirectToSignup2(
          signup1Data,
          variation2
            ? OCS_ADDRESS_STEP_FALLBACK_FORM
            : OCS_EMAIL_STEP_FALLBACK_FORM,
          history,
        ),
      );
      throw err;
    }
  };

export const initSignup2 = (userInfo, history) => (dispatch, getState) => {
  if (userInfo?.status === 'active') {
    goToLogin(history);
    return;
  }

  const signupData = getSignupData();
  let actionData = {
    type: signupConstants.INIT_SIGNUP_2,
  };
  if (signupData && signupData.signup2?.step) {
    Object.assign(actionData, signupData.signup1);
    actionData.signup2Data = signupData.signup2;

    dispatch(actionData);
    dispatch(setSignup2Step(signupData.signup2.step));
  } else {
    history.replace('/oneClickSignup');
  }
};

export const submitSignup2ConfirmInfoForm =
  (formData, isUpdated, history, trackingAttrs) =>
  async (dispatch, getState) => {
    try {
      const { signup2Data } = getState().oneClickSignup;
      const { direct2Premium } = getState().d2p || {};
      let isUserResurrected = null;
      dispatch(startLoading());

      const prefilledData = {
        identity: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address: formData.address,
          birthDate: formData.birthDate,
          mobileNumber: signup2Data.identity.mobileNumber,
          middleInitial: signup2Data.identity.middleInitial,
          ssn: formData.ssn,
        },
        password: formData.password,
        isUpdated,
        direct2Premium,
      };

      // Adding Marketing Attr to send to SLAPI
      let defaultMarkAttr = {
        provider: 'TUNE',
        prequalOfferAccept: isPrequalOfferAccept(),
        currentApp: 'CSWEB',
      };
      const marketingAttr = await guaranteeTrk(defaultMarkAttr);
      const irClickId = getMarketingCookieAttr('TransactionID');
      if (irClickId) {
        marketingAttr.partnerTxnId = irClickId;
      }
      prefilledData.marketingInfo = { ...marketingAttr };

      const response = await dispatch(ocs1_prefillFinish(prefilledData));
      dispatch(reportSignup2Submit(OCS_CONFIRM_INFO_FORM, trackingAttrs));
      isUserResurrected = response.isUserResurrected;
      if (response.dupSsn) {
        trackingAttrs.current_signup_flow = DUPE_SSN_FLOW;
      }
      const { userInfo } = await dispatch(getFreshUserInfo());
      await dispatch(
        postSignUpHandler(userInfo, history, isUserResurrected, trackingAttrs),
      );
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitSignup2ConfirmInfoForm.OneClickSignup.actions',
      );
      if (isCreditProfileError(err)) {
        setTempPassword(formData.password);
      }
      dispatch(goToEditAllForm(formData));
      throw err;
    } finally {
      dispatch(stopLoading());
    }
  };

export const goToEditAllForm = confirmInfoData => (dispatch, getState) => {
  dispatch(standardEventHandler(ocsFlowTracking[OCS_EDIT_ALL_FORM]));
  const signupData = getSignupData();
  const cleanConfirmInfoData = Object.assign({}, confirmInfoData);
  delete cleanConfirmInfoData.password;
  signupData.signup2.step = OCS_EDIT_ALL_FORM;
  signupData.signup2.confirmInfoData = cleanConfirmInfoData; //clean data for local storage
  persistSignupData(signupData);

  signupData.signup2.confirmInfoData = confirmInfoData;
  dispatch({
    type: signupConstants.INIT_EDIT_ALL_FORM,
    signup2Data: signupData.signup2,
  });
};

export const initDupeSsnLoading = formData => (dispatch, getState) => {
  dispatch(standardEventHandler(ocsFlowTracking[DUPE_SSN_FLOW]));
  dispatch({
    type: signupConstants.INIT_DUPE_SSN_LOADING,
    dupeSsnFirstName: formData.firstName,
  });
  dispatch(stopLoading());
  dispatch(setSignup2Step(OCS_DUPE_SSN_LOADING));
};

const userLogin =
  (registrationRes, formData, trackingAttrs, isSsnValidation) =>
  async (dispatch, getState) => {
    const isDupSsn = registrationRes.dupSsn;
    if (isDupSsn) {
      dispatch(initDupeSsnLoading(formData));
    }

    try {
      const email = registrationRes.email;
      dispatch({
        type: LOGIN_REQUEST,
        email,
      });
      const auth = await dispatch(
        ocs1_loginUser(
          email,
          registrationRes.password,
          null,
          getCookieValue('sessionIdentifier'),
        ),
      );
      if (getCookieValue('cs-api-sessid')) {
        deleteCookieValueForCSDomain('cs-api-sessid');
      }
      const t = moment();
      t.add(auth.expiresSeconds, 's');
      setUserLogoutTime(t.format());
      dispatch({ type: LOGIN_SUCCESS, user: { ...auth } });

      //start tracking
      initUserTracking(registrationRes, auth.userId, auth.sessionIdentifier);
      if (!isDupSsn) {
        dispatch(
          standardEventHandler(
            SIGNUP_ACCOUNT_CREATED,
            Object.assign(
              {
                'Sendex Score': registrationRes.sendexScore,
                forceSendUnified: true,
              },
              trackingAttrs,
            ),
          ),
        );

        if (!isSsnValidation) {
          reportOptimizely(OPT_SIGNUP_ACCOUNT_CREATED);
        }
      }
      return auth;
    } catch (err) {
      dispatch({ type: LOGIN_FAILURE });
      throw err;
    }
  };

export const postSignUpHandler =
  (userInfo, history, isUserResurrected, trackingAttrs) =>
  async (dispatch, getState) => {
    const state = getState();
    const d2pTracking = getCommonTrackingD2PAttributes({
      d2pConfig: state?.d2p,
    });
    manuallyActivatePage('one_click_signup_finish');

    const updatePixelFiredFlag = async _ => {
      try {
        await getAffiliatePixels();
      } catch (err) {
        logger.error('Error updating client pixels "pixelFired" flag', err);
      }
    };

    const sendRegisterEvent = async () => {
      reportOptimizely('ViewSignupFinish');

      if (!isUserResurrected) {
        await updatePixelFiredFlag();
        triggerClientPixel(userInfo);
      }

      try {
        const [userInfo, creditInfo] = await Promise.all([
          dispatch(getUserInfo(true)),
          dispatch(retrieveCreditProfile()),
        ]);
        const attrs = getAttributesForRegister(creditInfo);
        const userAttrs = buildUserMeta(userInfo);
        const mxpAttrs = Object.assign(
          { forceSendUnified: true },
          attrs,
          userAttrs,
          trackingAttrs,
        );
        const eventName = 'Register';
        //  executing identify user after user finish sign up flow
        identifyUser();
        if (!isUserRegisteredBefore(userInfo)) {
          // executing track SignupForTvScientific for tvScientific
          trackSignupForTvScientific('complete_registration');
        }

        brazeEventTrack(eventName, attrs);
        dispatch(
          standardEventHandler(REGISTER, {
            ...mxpAttrs,
            ...d2pTracking,
          }),
        );
        reportOptimizely(OPT_SIGNUP_COMPLETE);
        pushUserProfileOptAttributes(creditInfo);
      } catch (err) {
        logger.reportAPIError(err, 'postSignupHandler.OneClickSignup.actions');
      }
    };

    //signupFinish tasks
    try {
      const freshUserInfo = await dispatch(getUserInfo(true));
      if (isPendingPaymentOnSignUp(freshUserInfo)) {
        dispatch({
          type: LOAD_D2P_DATA,
          postSignUpData: {
            isUserResurrected,
            trackingAttrs,
          },
        });
        history?.replace('/d2p/checkout/oneclickSignup');
      } else {
        await sendRegisterEvent();
        await dispatch(transitionOverview(freshUserInfo, history));
        await dispatch({ type: LOAD_D2P_DATA, d2pTracking: null });
      }
    } catch (err) {
      logger.logFatalClientError(err);
      logger.reportAPIError(err, 'postSignUpHandler');
      logger.errorException('Error sending register ', err);
      throw err;
    }
  };

const transitionOverview = (userInfo, history) => (dispatch, getState) => {
  const state = getState();
  const d2pTracking = getCommonTrackingD2PAttributes({ d2pConfig: state?.d2p });
  setCookieWithExpiration('usrTkn', userInfo.userId, 100, null, null, true);
  setFirstNameUser(userInfo.firstName);

  const usrAttrs = buildUserMeta(userInfo);
  dispatch(
    standardEventHandler(SIGNUP_FINISH, {
      ...usrAttrs,
      forceSendUnified: true,
      ...d2pTracking,
    }),
  );
  history.push(isPremium(userInfo) ? OVERVIEW_WELCOME_URL : OVERVIEW_URL);
};

const reportSignup1Submit = (step, trackingAttrs) => dispatch => {
  reportOptimizely(optEventsSignupSubmit[step]);
  dispatch(
    standardEventHandler(
      CLICK,
      Object.assign(
        {
          'Click Type': 'One Click Signup 1 - Submit',
          click_title: 'Sign up',
        },
        trackingAttrs,
      ),
    ),
  );
};

const reportSignup1OTPSubmit = trackingAttrs => dispatch => {
  reportOptimizely(optEventsSignupSubmit[OCS_OTP_SENT]);
  dispatch(
    standardEventHandler(
      CLICK,
      Object.assign(
        {
          'Click Type': 'One Click Signup 1 OTP Submit',
          click_title: 'Check your texts',
        },
        trackingAttrs,
      ),
    ),
  );
};

export const reportSignup2Submit = (step, trackingAttrs) => dispatch => {
  reportOptimizely(optEventsSignupSubmit[step]);
  dispatch(
    standardEventHandler(
      CLICK,
      Object.assign(
        {
          'Click Type': 'One Click Signup 2 - Submit',
          click_title: 'Submit',
        },
        trackingAttrs,
      ),
    ),
  );
};

export const completeFreeAccountRegistrationD2P = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(ocs1_completeFreeAccountRegistration())
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const initSsnValidation = () => ({
  type: signupConstants.INIT_SSN_VALIDATION,
});

export const setFormInitCompleted = () => ({
  type: signupConstants.FORM_INIT_COMPLETED,
});

export const clearSignupState = () => ({
  type: signupConstants.CLEAR_SIGNUP_STATE,
});

export const setSignup1Step = step => ({
  type: signupConstants.SET_SIGNUP_1_STEP,
  step: step,
});

export const setSignup2Step = step => ({
  type: signupConstants.SET_SIGNUP_2_STEP,
  step: step,
});

export const startLoading = () => ({
  type: signupConstants.LOADING_START,
});

export const stopLoading = () => ({
  type: signupConstants.LOADING_FINISH,
});

export const setEligibilityResponse = eligibleForPrefill => ({
  type: signupConstants.SET_SIGNUP_ELIGIBLE_FOR_PREFILL,
  eligibleForPrefill,
});

export const setProveElegibleAudience = proveElegibleAudience => ({
  type: signupConstants.SET_SIGNUP_PROVE_ELEGIBLE_AUDIENCE,
  proveElegibleAudience,
});

export const setPrefillPhoneEmailResponse = prefillPhoneEmail => ({
  type: signupConstants.SET_SIGNUP_PREFILL_PHONE_EMAIL,
  prefillPhoneEmail,
});

export const setGoOneClickSignup2 = () => ({
  type: signupConstants.SET_ONE_CLICK_SIGNUP_2,
});

export const setVerifyOTPConfig = verifyOTPConfig => ({
  type: signupConstants.SET_VERIFY_OTP_CONFIG,
  verifyOTPConfig,
});
