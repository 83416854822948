export const PRODUCTION = 'production';
export const LOCAL = 'development';
export const TEST = 'testserver';
export const PREPROD = 'preprod';
export const PREPROD_LOCAL = 'preprodlocal';
export const DEMO = 'DEMO';
export const DEFAULT = 'default-app-environment';
export const PERF = 'PERF';
export const REACT_APP_BRAND = 'Sesame';
export const HELMET_TITLE = 'Credit Sesame';
export const APP_FOOTER_MESSAGE = 'Credit Sesame, Inc.';

const REACT_APP_SLAPI_HOST = 'REACT_APP_SLAPI_HOST';
const PUBLIC_URL = 'PUBLIC_URL';
const REACT_APP_BASE_URL = 'REACT_APP_BASE_URL';
const REACT_APP_RECURLY_KEY = 'REACT_APP_RECURLY_KEY';
const REACT_APP_SLAPI_KEY = 'REACT_APP_SLAPI_KEY';
const REACT_APP_IS_TEST = 'REACT_APP_IS_TEST';
const REACT_APP_STACK_BASE_URL = 'REACT_APP_STACK_BASE_URL';
const REACT_APP_PUBLIC_SITE_URL = 'REACT_APP_PUBLIC_SITE_URL';
const REACT_APP_AWS_LOCATORS_URL = 'REACT_APP_AWS_LOCATORS_URL';
const REACT_APP_GTM_ID = 'REACT_APP_GTM_ID';
const REACT_APP_OPT_SRC = 'REACT_APP_OPT_SRC';
const TRUST_EV_TOKEN = 'TRUST_EV_TOKEN';
const REACT_APP_OPTIMIZELY_PREFIX = 'REACT_APP_OPTIMIZELY_PREFIX';
const OPTIMIZELY_KEY = 'OPTIMIZELY_KEY';
const OPTIMIZELY_URL_TEMPLATE = 'OPTIMIZELY_URL_TEMPLATE';
const DEFAULT_NETWORK_TIMEOUT = 'DEFAULT_NETWORK_TIMEOUT';
const COGNIGY_CONFIG_URL = 'COGNIGY_CONFIG_URL';
const MIXPANEL_TOKEN = 'MIXPANEL_TOKEN';
const MIXPANEL_VISITOR_TOKEN = 'MIXPANEL_VISITOR_TOKEN';
const GET_INJECT_IOVATION_SNIPPET = 'GET_INJECT_IOVATION_SNIPPET';
const IGNORE_IF_2FA_ACTIVE = 'IGNORE_IF_2FA_ACTIVE';
const LOGIN_REROUTING_ACTIVE = 'LOGIN_REROUTING_ACTIVE';
const CHECK_IF_TEST_USER = 'CHECK_IF_TEST_USER';

export const ONE_BUREAU_COGNIGY_CONFIG_URL = 'ONE_BUREAU_COGNIGY_CONFIG_URL';
export const THREE_BUREAU_COGNIGY_CONFIG_URL =
  'THREE_BUREAU_COGNIGY_CONFIG_URL';
const CHAT_API_KEY = 'CHAT_API_KEY';

const getEnvironmentKey = () => {
  const host = window.location.host;
  if (global.isUnitTestSuite) return TEST;
  if (
    host === 'secured.creditsesame.com' ||
    host === 'morpheus.creditsesame.com'
  ) {
    return PRODUCTION;
  } else if (host.indexOf('local') >= 0 && host.indexOf('preprod') >= 0) {
    return PREPROD_LOCAL;
  } else if (host.indexOf('preprod') >= 0) {
    return PREPROD;
  } else if (host.indexOf('local') >= 0) {
    return LOCAL;
  } else if (host.indexOf('demo') >= 0) {
    return DEMO;
  } else if (host.indexOf('test') >= 0) {
    return TEST;
  } else if (host.indexOf('perf') >= 0) {
    return PERF;
  } else {
    return LOCAL;
  }
};

/**
 * DEFAULT_NETWORK_TIMEOUT varies per environment to support QA using charles
 * breakpoints for debuggin
 *
 */
const environments = {
  [DEMO]: {
    PUBLIC_URL: 'https://morpheus-demo.creditsesame.com',
    REACT_APP_SLAPI_HOST: 'https://api-dr.creditsesame.com',
    REACT_APP_SLAPI_KEY: '69ebb9fe-0dc2-47d7-ba58-1da9dcf8a402',
    REACT_APP_STACK_BASE_URL: 'https://api',
    REACT_APP_PUBLIC_SITE_URL: 'https://www.creditsesame.com',
    REACT_APP_AWS_LOCATORS_URL:
      'https://li64ztedmd.execute-api.us-east-1.amazonaws.com/Prod/get-locations',
    REACT_APP_OPT_SRC: '173671602.js',
    REACT_APP_RECURLY_KEY: 'ewr1-XNbE2pvL1k59pz4KjHTs6r',
    REACT_APP_GTM_ID: 'GTM-TV34WQ5',
    [TRUST_EV_TOKEN]: '1a078fd742d040dabf6832e5d360e4a2',
    REACT_APP_OPTIMIZELY_PREFIX: 'ProdX',
    OPTIMIZELY_KEY: '6LrH8Qennue7RBXRRtr4F',
    OPTIMIZELY_URL_TEMPLATE:
      'https://secured.creditsesame.com/datafiles/%s.json',
    COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/95f60dc82cc3089601a21b92cdda9d66e791c7036d018f88250a8907eab0974f',
    ONE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/b4a4c650f01c4a4085e589d86451eff96937c93bfeeb1fc1150a8e33829d2082',
    THREE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/8b02f61de0f973aa20691f6e82700763437bb35b0c35b394a6223cf7e45bc934',
    DEFAULT_NETWORK_TIMEOUT: 300000,
    CHAT_API_KEY: 'd2527804-403b-11ee-9456-002590831dc0',
    MIXPANEL_TOKEN: 'ceadc29366c1d67746c70b09c247e720',
    MIXPANEL_VISITOR_TOKEN: '40919d2228fb88c6fc18de142bf7e4a7',
    GET_INJECT_IOVATION_SNIPPET: false,
    IGNORE_IF_2FA_ACTIVE: false,
    LOGIN_REROUTING_ACTIVE: false,
    CHECK_IF_TEST_USER: false,
  },
  [LOCAL]: {
    REACT_APP_SLAPI_HOST: 'https://test32.hq.creditsesame.com',
    REACT_APP_SLAPI_KEY: '748fd156-5b02-4cdf-b006-c82444d40703',
    REACT_APP_STACK_BASE_URL: 'https://api-dev',
    REACT_APP_PUBLIC_SITE_URL: 'https://stage.creditsesame.com',
    REACT_APP_AWS_LOCATORS_URL:
      'https://li64ztedmd.execute-api.us-east-1.amazonaws.com/Prod/get-locations',
    REACT_APP_OPT_SRC: '2318360255.js',
    REACT_APP_RECURLY_KEY: 'ewr1-sriIeChYQzclw7WNFsZDGh',
    REACT_APP_GTM_ID: 'GTM-PXNCHW7',
    [TRUST_EV_TOKEN]: '1a078fd742d040dabf6832e5d360e4a2',
    REACT_APP_OPTIMIZELY_PREFIX: 'LocalX',
    OPTIMIZELY_KEY: '6LrH8Qennue7RBXRRtr4F',
    OPTIMIZELY_URL_TEMPLATE:
      'https://secured.creditsesame.com/datafiles/%s.json',
    COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/95f60dc82cc3089601a21b92cdda9d66e791c7036d018f88250a8907eab0974f',
    ONE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/b4a4c650f01c4a4085e589d86451eff96937c93bfeeb1fc1150a8e33829d2082',
    THREE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/8b02f61de0f973aa20691f6e82700763437bb35b0c35b394a6223cf7e45bc934',
    DEFAULT_NETWORK_TIMEOUT: 300000,
    CHAT_API_KEY: 'd2527804-403b-11ee-9456-002590831dc0',
    MIXPANEL_TOKEN: 'ceadc29366c1d67746c70b09c247e720',
    MIXPANEL_VISITOR_TOKEN: '40919d2228fb88c6fc18de142bf7e4a7',
    GET_INJECT_IOVATION_SNIPPET: false,
    IGNORE_IF_2FA_ACTIVE: false,
    LOGIN_REROUTING_ACTIVE: false,
    CHECK_IF_TEST_USER: false,
  },
  [PREPROD]: {
    PUBLIC_URL: 'https://preprod-morpheus.creditsesame.com',
    REACT_APP_SLAPI_HOST: 'https://preprodapi.creditsesame.com',
    REACT_APP_SLAPI_KEY: '826b940a-6e2e-44db-aa40-7c4ec51731eb',
    REACT_APP_STACK_BASE_URL: 'https://api-staging',
    REACT_APP_PUBLIC_SITE_URL: 'https://stage.creditsesame.com',
    REACT_APP_AWS_LOCATORS_URL:
      'https://li64ztedmd.execute-api.us-east-1.amazonaws.com/Prod/get-locations',
    REACT_APP_OPT_SRC: '2338261967.js',
    REACT_APP_RECURLY_KEY: 'ewr1-jSRDvfpy8vGtZmpC6RUunL',
    REACT_APP_GTM_ID: 'GTM-TV34WQ5',
    [TRUST_EV_TOKEN]: '1a078fd742d040dabf6832e5d360e4a2',
    REACT_APP_OPTIMIZELY_PREFIX: 'PreprodX',
    OPTIMIZELY_KEY: 'SFLv3qztbof4zhUDgnthx',
    OPTIMIZELY_URL_TEMPLATE:
      'https://secured.creditsesame.com/datafiles/%s.json',
    COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/95f60dc82cc3089601a21b92cdda9d66e791c7036d018f88250a8907eab0974f',
    ONE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/b4a4c650f01c4a4085e589d86451eff96937c93bfeeb1fc1150a8e33829d2082',
    THREE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/8b02f61de0f973aa20691f6e82700763437bb35b0c35b394a6223cf7e45bc934',
    DEFAULT_NETWORK_TIMEOUT: 300000,
    CHAT_API_KEY: 'd2527804-403b-11ee-9456-002590831dc0',
    MIXPANEL_TOKEN: '7651ee6f95d6c5c7c05cf5ae93c8f668',
    MIXPANEL_VISITOR_TOKEN: '40919d2228fb88c6fc18de142bf7e4a7',
    GET_INJECT_IOVATION_SNIPPET: false,
    IGNORE_IF_2FA_ACTIVE: false,
    LOGIN_REROUTING_ACTIVE: false,
    CHECK_IF_TEST_USER: false,
  },
  [TEST]: {
    //Please Uncomment REACT_APP_SLAPI_HOST to run test environment on your local. Please comment before you commit
    //REACT_APP_SLAPI_HOST: 'https://test32.hq.creditsesame.com',
    REACT_APP_SLAPI_HOST: '',
    REACT_APP_SLAPI_KEY: '748fd156-5b02-4cdf-b006-c82444d40703',
    REACT_APP_STACK_BASE_URL: 'https://api-dev',
    REACT_APP_PUBLIC_SITE_URL: 'https://stage.creditsesame.com',
    REACT_APP_AWS_LOCATORS_URL:
      'https://li64ztedmd.execute-api.us-east-1.amazonaws.com/Prod/get-locations',
    REACT_APP_OPT_SRC: '2338621087.js',
    REACT_APP_RECURLY_KEY: 'ewr1-sriIeChYQzclw7WNFsZDGh',
    REACT_APP_GTM_ID: 'GTM-WSFFK5S',
    [TRUST_EV_TOKEN]: '1a078fd742d040dabf6832e5d360e4a2',
    REACT_APP_OPTIMIZELY_PREFIX: 'TestX',
    OPTIMIZELY_KEY: '6LrH8Qennue7RBXRRtr4F',
    OPTIMIZELY_URL_TEMPLATE:
      'https://secured.creditsesame.com/datafiles/%s.json',
    COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/95f60dc82cc3089601a21b92cdda9d66e791c7036d018f88250a8907eab0974f',
    ONE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/b4a4c650f01c4a4085e589d86451eff96937c93bfeeb1fc1150a8e33829d2082',
    THREE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/8b02f61de0f973aa20691f6e82700763437bb35b0c35b394a6223cf7e45bc934',
    DEFAULT_NETWORK_TIMEOUT: 300000,
    CHAT_API_KEY: 'd2527804-403b-11ee-9456-002590831dc0',
    MIXPANEL_TOKEN: 'ceadc29366c1d67746c70b09c247e720',
    MIXPANEL_VISITOR_TOKEN: '40919d2228fb88c6fc18de142bf7e4a7',
    GET_INJECT_IOVATION_SNIPPET: false,
    IGNORE_IF_2FA_ACTIVE: false,
    LOGIN_REROUTING_ACTIVE: false,
    CHECK_IF_TEST_USER: false,
  },
  [PRODUCTION]: {
    PUBLIC_URL: 'https://secured.creditsesame.com',
    REACT_APP_SLAPI_HOST: 'https://api.creditsesame.com',
    REACT_APP_SLAPI_KEY: '69ebb9fe-0dc2-47d7-ba58-1da9dcf8a402',
    REACT_APP_STACK_BASE_URL: 'https://api',
    REACT_APP_PUBLIC_SITE_URL: 'https://www.creditsesame.com',
    REACT_APP_AWS_LOCATORS_URL:
      'https://li64ztedmd.execute-api.us-east-1.amazonaws.com/Prod/get-locations',
    REACT_APP_OPT_SRC: '173671602.js',
    REACT_APP_RECURLY_KEY: 'ewr1-XNbE2pvL1k59pz4KjHTs6r',
    REACT_APP_GTM_ID: 'GTM-KZ23HRX',
    [TRUST_EV_TOKEN]: 'a0c7a3edbe5a4534be30382e5c13ab5f',
    REACT_APP_OPTIMIZELY_PREFIX: 'ProdX',
    OPTIMIZELY_KEY: '6HvckKfd7LSiZTh6biG4x',
    OPTIMIZELY_URL_TEMPLATE:
      'https://secured.creditsesame.com/datafiles/%s.json',
    COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/95f60dc82cc3089601a21b92cdda9d66e791c7036d018f88250a8907eab0974f',
    ONE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/b4a4c650f01c4a4085e589d86451eff96937c93bfeeb1fc1150a8e33829d2082',
    THREE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/8b02f61de0f973aa20691f6e82700763437bb35b0c35b394a6223cf7e45bc934',
    DEFAULT_NETWORK_TIMEOUT: 30000,
    CHAT_API_KEY: '835075e0-4105-11ee-b38a-0cc47a81b19e',
    MIXPANEL_TOKEN: 'ea352fa39970a595d62c44f1c67c9454',
    MIXPANEL_VISITOR_TOKEN: '6dc5ae47a9f2d0f00f01818995ca6fa2',
    GET_INJECT_IOVATION_SNIPPET: false,
    IGNORE_IF_2FA_ACTIVE: false,
    LOGIN_REROUTING_ACTIVE: false,
    CHECK_IF_TEST_USER: false,
  },
  [PREPROD_LOCAL]: {
    PUBLIC_URL: 'https://local-preprod.creditsesame.com:3000',
    REACT_APP_BASE_URL: 'http://local-preprod.creditsesame.com:3000/',
    REACT_APP_SLAPI_HOST: 'https://preprodapi.creditsesame.com',
    REACT_APP_SLAPI_KEY: '826b940a-6e2e-44db-aa40-7c4ec51731eb',
    REACT_APP_STACK_BASE_URL: 'https://api-staging',
    REACT_APP_PUBLIC_SITE_URL: 'https://stage.creditsesame.com',
    REACT_APP_AWS_LOCATORS_URL:
      'https://li64ztedmd.execute-api.us-east-1.amazonaws.com/Prod/get-locations',
    REACT_APP_OPT_SRC: '2318360255.js',
    REACT_APP_RECURLY_KEY: 'ewr1-jSRDvfpy8vGtZmpC6RUunL',
    REACT_APP_GTM_ID: 'GTM-P8FJ6DL',
    [TRUST_EV_TOKEN]: '1a078fd742d040dabf6832e5d360e4a2',
    REACT_APP_OPTIMIZELY_PREFIX: 'LocalX',
    OPTIMIZELY_KEY: '6LrH8Qennue7RBXRRtr4F',
    OPTIMIZELY_URL_TEMPLATE:
      'https://secured.creditsesame.com/datafiles/%s.json',
    COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/95f60dc82cc3089601a21b92cdda9d66e791c7036d018f88250a8907eab0974f',
    ONE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/b4a4c650f01c4a4085e589d86451eff96937c93bfeeb1fc1150a8e33829d2082',
    THREE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/8b02f61de0f973aa20691f6e82700763437bb35b0c35b394a6223cf7e45bc934',
    DEFAULT_NETWORK_TIMEOUT: 300000,
    CHAT_API_KEY: 'd2527804-403b-11ee-9456-002590831dc0',
    MIXPANEL_TOKEN: 'ceadc29366c1d67746c70b09c247e720',
    MIXPANEL_VISITOR_TOKEN: '40919d2228fb88c6fc18de142bf7e4a7',
    GET_INJECT_IOVATION_SNIPPET: false,
    IGNORE_IF_2FA_ACTIVE: false,
    LOGIN_REROUTING_ACTIVE: false,
    CHECK_IF_TEST_USER: false,
  },
  [PERF]: {
    PUBLIC_URL: 'http://morpheus-perf.creditsesame.com',
    REACT_APP_BASE_URL: 'http://morpheus-perf.creditsesame.com/',
    REACT_APP_SLAPI_HOST: 'https://perfapi.creditsesame.com',
    REACT_APP_SLAPI_KEY: '3377f373-b857-4407-a487-77a23ab375bb',
    REACT_APP_STACK_BASE_URL: 'https://api-staging',
    REACT_APP_PUBLIC_SITE_URL: 'https://stage.creditsesame.com',
    REACT_APP_AWS_LOCATORS_URL:
      'https://li64ztedmd.execute-api.us-east-1.amazonaws.com/Prod/get-locations',
    REACT_APP_OPT_SRC: '2338261967.js',
    REACT_APP_RECURLY_KEY: 'ewr1-jSRDvfpy8vGtZmpC6RUunL',
    REACT_APP_GTM_ID: 'GTM-TV34WQ5',
    [TRUST_EV_TOKEN]: '1a078fd742d040dabf6832e5d360e4a2',
    REACT_APP_OPTIMIZELY_PREFIX: 'PreprodX',
    OPTIMIZELY_KEY: '6LrH8Qennue7RBXRRtr4F',
    OPTIMIZELY_URL_TEMPLATE:
      'https://secured.creditsesame.com/datafiles/%s.json',
    COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/95f60dc82cc3089601a21b92cdda9d66e791c7036d018f88250a8907eab0974f',
    ONE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/b4a4c650f01c4a4085e589d86451eff96937c93bfeeb1fc1150a8e33829d2082',
    THREE_BUREAU_COGNIGY_CONFIG_URL:
      'https://endpoint-app-us.cognigy.ai/8b02f61de0f973aa20691f6e82700763437bb35b0c35b394a6223cf7e45bc934',
    DEFAULT_NETWORK_TIMEOUT: 300000,
    CHAT_API_KEY: 'd2527804-403b-11ee-9456-002590831dc0',
    MIXPANEL_TOKEN: 'ceadc29366c1d67746c70b09c247e720',
    MIXPANEL_VISITOR_TOKEN: '40919d2228fb88c6fc18de142bf7e4a7',
    GET_INJECT_IOVATION_SNIPPET: false,
    IGNORE_IF_2FA_ACTIVE: false,
    LOGIN_REROUTING_ACTIVE: false,
    CHECK_IF_TEST_USER: false,
  },
};

const getKey = key => {
  const env = getEnvironmentKey();
  return environments[env][key];
};

export const getPublicUrl = () => getKey(PUBLIC_URL);
export const getPreprodLocalUrl = () => getKey(REACT_APP_BASE_URL);
export const getPublicSiteUrl = () => getKey(REACT_APP_PUBLIC_SITE_URL);
export const getAwsLocatorsUrl = () => getKey(REACT_APP_AWS_LOCATORS_URL);
export const getBankingUrl = () => getKey(REACT_APP_STACK_BASE_URL);
export const isTest = () => getKey(REACT_APP_IS_TEST) === 'true';
export const getSlapiApiKey = () => getKey(REACT_APP_SLAPI_KEY);
export const getEnvironment = () => getEnvironmentKey();
export const getRecurlyApiKey = () => getKey(REACT_APP_RECURLY_KEY);
export const getGtmId = () => getKey(REACT_APP_GTM_ID);
export const getOptimizelySource = () => getKey(REACT_APP_OPT_SRC);
export const getTrustEvKey = () => getKey(TRUST_EV_TOKEN);
export const getOptimizelyPrefix = () => getKey(REACT_APP_OPTIMIZELY_PREFIX);
export const getOptimizelyKey = () => getKey(OPTIMIZELY_KEY);
export const getOptimizelyUrlTemplate = () => getKey(OPTIMIZELY_URL_TEMPLATE);
export const getDefaultNetworkTimeout = () => getKey(DEFAULT_NETWORK_TIMEOUT);
export const getCognigyConfigUrl = cognigyUrlType =>
  getKey(cognigyUrlType ? cognigyUrlType : COGNIGY_CONFIG_URL);
export const getChatApiKey = () => getKey(CHAT_API_KEY);
export const getMixpanelToken = () => getKey(MIXPANEL_TOKEN);
export const getMixpanelVisitorToken = () => getKey(MIXPANEL_VISITOR_TOKEN);

export const getImageBasePath = () => {
  return `/`;
};
export const getBrandImagePath = () => {
  return `/`;
};
export const getSlapiUrl = () => {
  return `${getKey(REACT_APP_SLAPI_HOST)}/api/v2`;
};
export const getInjectIovation = () => getKey(GET_INJECT_IOVATION_SNIPPET);

export const isProduction = () => getEnvironment() === PRODUCTION;
export const isTestEnv = () => getEnvironment() === TEST;
export const isLocalPreprod = () => getEnvironment() === PREPROD_LOCAL;
export const isPreprod2 = () => {
  return (
    getEnvironment() === PREPROD &&
    window.location.host.indexOf('preprod2') >= 0
  );
};

export const getTrkClickUrl = () => getKey(REACT_APP_PUBLIC_SITE_URL);

export const REACT_APP_BRAZE_TEST_KEY = '70b4aeb1-a29d-43df-8604-200ea352495c';
export const REACT_APP_BRAZE_PROD_KEY = '23c4f64a-7bd0-4809-90fe-fc88f8bf6d39';
export const REACT_APP_BRAZE_BASE_URL = 'https://sdk.iad-03.braze.com/api/v3';
export const isDataDomeEnabled = () => true;
export const DOMAIN = 'creditsesame.com';
export const shouldRedirectToSignUpError = () => false;

export const getIgnoreIf2FAActive = () => getKey(IGNORE_IF_2FA_ACTIVE);

export const isLoginReroutingActive = () => getKey(LOGIN_REROUTING_ACTIVE);

export const isPrequalOfferAccept = () => true;

export const checkIfTestUser = () => getKey(CHECK_IF_TEST_USER);

export const OFFERS_IMAGE_PROXY = 'https://image.creditsesame.com/ccimages/';

export const getLoginUrl = params => `/login${params}`;
