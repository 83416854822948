import { useState } from 'react';
import { visitorReportEvent } from 'externals/_tracking/index';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { useHistory, useLocation } from 'react-router-dom';
import { BANKING_VERTICAL } from 'types/mrph.vertical.constants';
import { getAffiliateLP } from 'helpers/signup.helpers';
import { getItem } from 'helpers/localstorage';
import { getDMPromoCode } from 'helpers/marketing.helper';
import { goToLogin } from 'customHelpersPath/redirectLogin';

const isDMNewUser = () => {
  return getItem('isDMSignup') === 'true';
};

export const isDMFromLoginOrLink = () => {
  const lpList = ['savenow_nocode', 'savemore_nocode'];
  const affiliateLP = getAffiliateLP();
  const isDMWithoutCode = lpList.includes(affiliateLP);
  const isDMFromLogin = getDMPromoCode() && !isDMNewUser();

  return isDMWithoutCode || isDMFromLogin;
};

export const useCashSignupState = isSignupLoading => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [cashTermsChecked, setCashTermsChecked] = useState(false);

  const isFromDM = isDMNewUser() || isDMFromLoginOrLink();
  const isFromCashLP = isFromDM;

  const isSignup1 = pathname.endsWith('/signup');
  const showLoginLink = isFromCashLP && isSignup1 && !isSignupLoading;

  const onClickCashTermsCheckbox = e => {
    e.preventDefault();
    visitorReportEvent(CLICK, {
      'Click Type': 'Cash Terms Checkbox',
      Vertical: BANKING_VERTICAL,
    });
    setCashTermsChecked(!cashTermsChecked);
  };

  const onClickLogin = () => {
    visitorReportEvent(CLICK, {
      'Click Type': 'Nav Bar - Log in',
    });
    goToLogin(history);
  };

  return {
    pathname,
    cashTermsChecked,
    isFromCashLP,
    isFromDM,
    hideProgressBar: isDMFromLoginOrLink(),
    showLoginLink,
    onClickCashTermsCheckbox,
    onClickLogin,
  };
};
